import React from 'react';

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Widgets from '@mui/icons-material/Widgets';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import axios from '../axiosClient.js';
import PageContext from '../contexts/PageContext.jsx';
import useAuth0Roles from '../hooks/auth0Roles.jsx';
import Alert from './common/Alert.jsx';
import NavButton from './common/NavButton.jsx';

function SideBar({
  // Props
  onClick,
}) {
  const { PageButtons } = React.useContext(PageContext);
  const [version, setVersion] = React.useState('');
  const [error, setError] = React.useState(null);

  const fetchVersion = React.useCallback(async () => {
    try {
      const versionNumber = await axios.get('/api/version');
      setVersion(versionNumber.data.version);
      setError(null);
    } catch (err) {
      setError(
        err.response?.data?.message ||
          err.response?.data ||
          err.message ||
          'Something bad happened...'
      );
    }
  }, []);

  React.useEffect(() => {
    fetchVersion();
  }, [fetchVersion]);

  const { roles } = useAuth0Roles();
  const hasTZ = React.useMemo(() => roles.includes('Scorecard TZ Medical Viewer'), [roles]);
  const hasTZMMS = React.useMemo(() => roles.includes('Scorecard TZMMS Viewer'), [roles]);
  const hasENGR = React.useMemo(() => roles.includes('Scorecard Engineering Viewer'), [roles]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      <List onClick={onClick}>
        {!!PageButtons && (
          <>
            {PageButtons}
            <Divider />
          </>
        )}
        {hasTZ && <NavButton Icon={<Widgets />} text="TZ Medical" to="/tz-medical" />}
        {hasTZMMS && <NavButton Icon={<Widgets />} text="TZMMS" to="/tzmms" />}
        {hasENGR && <NavButton Icon={<Widgets />} text="Engineering" to="/engineering" />}
      </List>
      <Typography
        data-cy="version-number"
        sx={{
          paddingLeft: 2,
          position: 'absolute',
          bottom: 0,
          paddingBottom: 1,
          fontSize: '0.75rem',
          opacity: 0.7,
        }}
      >
        v{version}
      </Typography>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
    </>
  );
}

export default SideBar;
