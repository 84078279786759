import React from 'react';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import UhOhRobot from '../assets/404-robot.svg';

//---------------------------------------------------------------------------
// Render
//---------------------------------------------------------------------------
function ErrorPage({
  // Props
  title,
  subtitle,
}) {
  return (
    <Container>
      <Grid
        container
        spacing={12}
        alignItems="center"
        justify="center"
        style={{
          minWidth: '100%',
          minHeight: '70vh',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Grid item xs={12} sm={6}>
          <h1>{title || '404 Page Not Found'}</h1>
          <Typography variant="subtitle1">
            {subtitle || 'Sorry, an unexpected error has occurred.'}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={UhOhRobot} alt="404" />
        </Grid>
      </Grid>
    </Container>
  );
}

export default ErrorPage;
