import React from 'react';
import PropTypes from 'prop-types';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Typography from '@mui/material/Typography';

function TextWithNewlines({ text, fontSize = '1rem' }) {
  const lines = text.split(/\r?\n/);
  return (
    <>
      {lines.map((line) => (
        <Typography key={line} style={{ fontSize }}>
          {line}
        </Typography>
      ))}
    </>
  );
}

TextWithNewlines.propTypes = {
  text: PropTypes.string,
  fontSize: PropTypes.string,
};

export default TextWithNewlines;
