import React from 'react';
import { DateTime } from 'luxon';

import CalculateIcon from '@mui/icons-material/Calculate';
import RuleIcon from '@mui/icons-material/Rule';

import useTheme from '@mui/material/styles/useTheme';

function MetricStatusIcon({
  // Props
  metric,
}) {
  const theme = useTheme();

  const lastFriday = React.useMemo(() => {
    const now = DateTime.local().set({ weekday: 5 });
    return now.minus({ weeks: 1 }).toISODate();
  }, []);

  if (metric.dataType !== 'manual') {
    return <CalculateIcon sx={{ color: theme.palette.text.icon }} />;
  }
  if (!metric.DataPoints[0]?.date) {
    return <RuleIcon color="error" />;
  }
  if (metric.DataPoints[0]?.date < lastFriday) {
    return <RuleIcon color="warning" />;
  }
  return null;
}
export default MetricStatusIcon;
