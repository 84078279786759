import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import PageLoading from './components/common/PageLoading.jsx';
import Layout from './components/Layout.jsx';
import useAuth0Roles from './hooks/auth0Roles.jsx';
import useCustomTheme from './hooks/customizeReactTheme.jsx';
import Page404 from './pages/Page404.jsx';
import Scorecard from './pages/Scorecard/index.jsx';
import { addAccessTokenInterceptor } from './axiosClient.js';

function App() {
  const { isLoading, isAuthenticated, error, loginWithRedirect, getAccessTokenSilently } =
    useAuth0();

  const theme = useCustomTheme();

  React.useEffect(() => {
    if (!isLoading && !isAuthenticated && !error) {
      // Use pathname for redirect - since we're staying within the application
      loginWithRedirect({
        appState: { returnTo: `${window.location.pathname}${window.location.search}` },
      });
    }
  }, [isLoading, isAuthenticated, error, loginWithRedirect]);

  React.useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  const { roles, rolesValid } = useAuth0Roles();
  const hasTZ = React.useMemo(() => roles.includes('Scorecard TZ Medical Viewer'), [roles]);
  const hasTZMMS = React.useMemo(() => roles.includes('Scorecard TZMMS Viewer'), [roles]);
  const hasENGR = React.useMemo(() => roles.includes('Scorecard Engineering Viewer'), [roles]);

  const redirect = React.useMemo(() => {
    if (!rolesValid) {
      return <PageLoading />;
    }
    if (hasTZ) {
      return <Navigate to="/tz-medical" replace />;
    }
    if (hasTZMMS) {
      return <Navigate to="/tzmms" replace />;
    }
    if (hasENGR) {
      return <Navigate to="/engineering" replace />;
    }
    return <Navigate to="/unauthorized" replace />;
  }, [hasENGR, hasTZ, hasTZMMS, rolesValid]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <header className="App-header">
          {error && (
            <Container maxWidth="lg">
              <div>Oops... {error.message}</div>
              <br />
              <Button
                variant="contained"
                onClick={() =>
                  loginWithRedirect({
                    appState: { returnTo: window.location.origin },
                  })
                }
              >
                Login
              </Button>
            </Container>
          )}
          {!error && (isLoading || !isAuthenticated) && <PageLoading />}
          {isAuthenticated && (
            <Layout>
              <Routes>
                <Route index path="/" element={redirect} />
                {hasTZ && <Route path="/tz-medical" element={<Scorecard company="tz-medical" />} />}
                {hasTZMMS && <Route path="/tzmms" element={<Scorecard company="tzmms" />} />}
                {hasENGR && (
                  <Route
                    path="/engineering"
                    element={<Scorecard company="tz-medical" group="Engineering" />}
                  />
                )}
                <Route
                  path="*"
                  element={
                    <Page404
                      title={!hasTZ && !hasTZMMS && 'Unauthorized'}
                      subtitle={
                        !hasTZ && !hasTZMMS && "Sorry, you don't have access to this application."
                      }
                    />
                  }
                />
              </Routes>
            </Layout>
          )}
        </header>
      </ThemeProvider>
    </div>
  );
}

export default App;
