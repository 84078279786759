// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import { useLocation } from 'react-router-dom';
import PropType from 'prop-types';

const SearchContext = React.createContext();

export function SearchProvider({ children }) {
  const [search, setSearch] = React.useState('');
  const [searchDecorator, setSearchDecorator] = React.useState(null);

  // Reset the search bar every time we navigate to a new page
  const location = useLocation();
  React.useEffect(() => {
    setSearch('');
  }, [location, setSearch]);

  const value = React.useMemo(
    () => ({
      search,
      searchDecorator,
      setSearch,
      setSearchDecorator,
    }),
    [search, searchDecorator]
  );

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
}

export default SearchContext;

SearchProvider.propTypes = {
  children: PropType.node.isRequired,
};
