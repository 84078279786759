/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

function FormStringInput({
  // Props
  control,
  name,
  label,
  id,
  type,
  inputProps,
  defaultValue,
  required = false,
  disabled = false,
  options,
  rules,
  variant = 'standard',
  multiline = false,
  autoFocus = false,
}) {
  const context = useFormContext();

  return (
    <Controller
      name={name}
      control={context?.control || control}
      defaultValue={defaultValue ?? context?.defaultValues[name]}
      shouldUnregister
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          required={required}
          label={label}
          id={id}
          type={type}
          inputProps={inputProps}
          select={!!options}
          variant={variant}
          disabled={disabled}
          fullWidth
          multiline={multiline}
          autoFocus={autoFocus}
          value={value}
          onChange={onChange}
          error={!!error}
          helperText={error ? error.message : null}
        >
          {!!options &&
            options.map((option) => (
              <MenuItem
                key={option.id}
                value={option.value ?? option.id}
                disabled={option.disabled}
                id={`${id}-${option.id}`}
                data-cy={`${id}-${option.id}`}
              >
                {option.name}
              </MenuItem>
            ))}
        </TextField>
      )}
      rules={rules}
    />
  );
}

FormStringInput.propTypes = {
  /**
   * The [control](https://react-hook-form.com/api/useform/control)
   * object provided by invoking `useForm`.
   * Optional when using `FormProvider`.
   *
   * @example
   * ```js
   * import { useForm } from 'react-hook-form';
   * const { control, handleSubmit, getValues } = useForm();
   * ```
   * pass in control
   */
  control: PropTypes.object,

  /**
   * Unique name of your input field
   */
  name: PropTypes.string.isRequired,

  /**
   * The label content
   */
  label: PropTypes.string,

  /**
   * The id of your input field
   */
  id: PropTypes.string,

  /**
   * Type of the `input` element
   */
  type: PropTypes.string,

  /**
   * Input props for the input field, this can set the minimum number the scroll can reach,
   * the maximum number the scroll can reach, and the precision the input can have.
   * *** This is important when using type: 'number' ***
   */
  inputProps: PropTypes.object,

  /**
   * **Important:** cannot apply undefined to `defaultValue`
   * * You need to either set `defaultValue` at the field-level
   *   or `useForm`'s `defaultValue`s.  Undefined is not a valid value.
   * * If your form will invoke `reset` with default values,
   *   you will need to provide `useForm` with `defaultValues`
   */
  defaultValue: PropTypes.node,

  /**
   * If `true`, the label is displayed as required
   * and the input element is required.
   */
  required: PropTypes.bool,

  /**
   * If `true` the component is disabled
   */
  disabled: PropTypes.bool,

  /**
   * If `true` enables a select menu
   */
  options: PropTypes.array,

  /**
   * Validation rules in the same format for `register`,
   * which includes:
   * required, min, man, minLength, maxLength, pattern, validate
   *
   * @example
   * ```jsx
   * rules={{ required: true }}
   * ```
   */
  rules: PropTypes.object,
  variant: PropTypes.string,
};

export default FormStringInput;
