// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';

// ───────────────────────────────────────────────────────────────────────────
// ─── MUI Icons
// ───────────────────────────────────────────────────────────────────────────
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';

// ───────────────────────────────────────────────────────────────────────────
// ─── MUI Components
// ───────────────────────────────────────────────────────────────────────────
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { alpha } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';

// ───────────────────────────────────────────────────────────────────────────
// ─── Application Contexts
// ───────────────────────────────────────────────────────────────────────────
import SearchContext from '../contexts/SearchContext.jsx';

//---------------------------------------------------------------------------
function SearchBar() {
  const { search, searchDecorator, setSearch } = React.useContext(SearchContext);
  const theme = useTheme();

  const handleSearch = React.useCallback(
    (event) => {
      setSearch(event.target.value);
      window.scrollTo(0, 0);
    },
    [setSearch]
  );
  const clearSearch = React.useCallback(() => {
    setSearch('');
    window.scrollTo(0, 0);
  }, [setSearch]);

  return (
    <OutlinedInput
      color="primary"
      fullWidth
      sx={{
        color: theme.palette.common.white,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        marginTop: '5px',
        marginBottom: '5px',
        borderRadius: '10px',
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
      }}
      data-cy="site-search"
      startAdornment={
        <InputAdornment sx={{ color: theme.palette.common.white }} position="start">
          <SearchIcon />
        </InputAdornment>
      }
      placeholder="Search here..."
      value={search}
      onChange={handleSearch}
      endAdornment={
        <InputAdornment sx={{ color: theme.palette.common.white }} position="end">
          <ButtonGroup variant="outlined">
            {search && (
              <IconButton
                size="large"
                aria-label="clear-search"
                data-cy="clear-search"
                color="inherit"
                onClick={clearSearch}
              >
                <CancelIcon />
              </IconButton>
            )}
            {searchDecorator}
          </ButtonGroup>
        </InputAdornment>
      }
    />
  );
}

export default SearchBar;
