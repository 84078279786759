import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import App from './App.jsx';
import reportWebVitals from './reportWebVitals.js';

function Auth0ProviderWithRedirectCallback({ children, ...props }) {
  const navigate = useNavigate();
  const onRedirectCallback = React.useCallback(
    (appState) => {
      // after login return to the previous page
      navigate((appState && appState.returnTo) || window.location.pathname);
    },
    [navigate]
  );

  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
}

const { audience, cacheLocation, clientId, domain } = window.globalConfig.auth0;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Auth0ProviderWithRedirectCallback
      domain={domain}
      clientId={clientId}
      cacheLocation={cacheLocation}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
        scope: 'openid profile email',
      }}
    >
      <App />
    </Auth0ProviderWithRedirectCallback>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
