import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const ROLES_CLAIM_KEY = 'https://tzmedical.io/roles';

export default function useAuth0Roles() {
  const { getIdTokenClaims, isAuthenticated } = useAuth0();
  const [roles, setRoles] = React.useState([]);
  const [rolesValid, setRolesValid] = React.useState(false);

  const getRoles = React.useCallback(async () => {
    if (isAuthenticated) {
      const claims = await getIdTokenClaims();
      if (claims) {
        const auth0Roles = claims[ROLES_CLAIM_KEY];

        setRoles(auth0Roles);
        setRolesValid(true);
      }
    }
  }, [getIdTokenClaims, isAuthenticated]);

  React.useEffect(() => {
    getRoles();
  }, [getRoles]);

  return { rolesValid, roles };
}
