import React from 'react';
import { useForm, useFormState } from 'react-hook-form';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import CancelButton from './common/CancelButton.jsx';
import FormStringInput from './common/FormStringInput.jsx';

function capitalize(text) {
  if (!text.includes(' ')) {
    return text[0].toUpperCase() + text.substr(1).toLowerCase();
  }
  return text
    .split(' ')
    .map((str) => capitalize(str))
    .join(' ');
}

function ConfirmDelete({
  // Props
  handleConfirmation,
  confirmKeyword,
  name,
  dialogTitle,
}) {
  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);

  //---------------------------------------------------------------------------
  // Update button state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);

  const closeForm = React.useCallback(() => setOpen(false), []);
  const handleClick = React.useCallback(() => setOpen((prev) => !prev), []);

  // We need this because just using the 'text-transform' property is not enough.
  // 'text-transform' doesn't actually change the text inside the div it just changes how it displays.
  const deleteButtonText = capitalize(`Delete ${name}`);
  const deleteDialogTitleText = capitalize(
    `Delete ${name}${dialogTitle ? ` - ${dialogTitle}` : ''}`
  );

  const { handleSubmit, control } = useForm();
  const { isDirty } = useFormState({ control });

  const onSubmit = React.useCallback(async () => {
    // Disable the submit button and display the spinner
    setLoading(true);
    handleConfirmation();
    setOpen(false);
    setLoading(false);
  }, [handleConfirmation]);

  return (
    <>
      <Button
        variant="text"
        data-cy={`${name}-delete-link`}
        sx={{
          marginLeft: 'auto',
          marginRight: '2.5rem',
          color: 'text.secondary',
          '&:hover': { textDecoration: 'underline' },
        }}
        onClick={handleClick}
      >
        {deleteButtonText}
      </Button>
      <Dialog open={open} onClose={closeForm} maxWidth="sm" fullWidth>
        <DialogTitle>{deleteDialogTitleText}</DialogTitle>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <DialogContent>
            <DialogContentText data-cy="delete-confirmation-text">
              If you are sure you want to delete this {name.toLowerCase()}, type{' '}
              <strong>{confirmKeyword}</strong> into the field below and press the confirmation
              button.
            </DialogContentText>
            <br />
            <FormStringInput
              control={control}
              name="keyword"
              id="keywordInput"
              variant="outlined"
              defaultValue=""
              rules={{
                required: `Please enter "${confirmKeyword}" to proceed.`,
                validate: {
                  equals: (val) => {
                    if (val !== confirmKeyword) {
                      return `Please enter "${confirmKeyword}" to proceed.`;
                    }
                    return true;
                  },
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Box m={2}>
              <CancelButton color="secondary" isDirty={isDirty} onClick={closeForm}>
                Cancel
              </CancelButton>
            </Box>
            <Box m={2}>
              <LoadingButton
                disabled={loading}
                data-cy={`${name}-delete-confirm`}
                variant="contained"
                color="secondary"
                type="submit"
                loading={loading}
              >
                Confirm
              </LoadingButton>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default ConfirmDelete;
