// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material UI Components
// ───────────────────────────────────────────────────────────────────────────
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

function NavButton({
  // Props
  Icon,
  text,
  to,
  onClick,
  'data-cy': dataCy,
}) {
  // Use location to highlight when our link matches the current page
  const location = useLocation();

  if (to) {
    return (
      <ListItemButton
        component={Link}
        to={to}
        onClick={onClick}
        selected={location.pathname === to}
        data-cy={dataCy}
        sx={{
          '&.Mui-selected': {
            backgroundColor: 'divider',
            '&:hover': { backgroundColor: 'divider' },
          },
        }}
      >
        <ListItemIcon sx={{ color: 'text.secondary' }}>{Icon}</ListItemIcon>
        <ListItemText primaryTypographyProps={{ color: 'text.secondary' }} primary={text} />
      </ListItemButton>
    );
  }

  return (
    <ListItemButton component={Link} onClick={onClick} data-cy={dataCy}>
      <ListItemIcon sx={{ color: 'text.secondary' }}>{Icon}</ListItemIcon>
      <ListItemText primaryTypographyProps={{ color: 'text.secondary' }} primary={text} />
    </ListItemButton>
  );
}

export default NavButton;

NavButton.propTypes = {
  Icon: PropTypes.node,
  text: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  'data-cy': PropTypes.string,
};
