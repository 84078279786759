import React from 'react';

import Tooltip from '@mui/material/Tooltip';

import TextWithNewlines from './common/TextWithNewlines.jsx';

export function useValueWithUnits() {
  return React.useCallback(
    ({
      // Props
      value,
      units,
      goalType,
      fixedDigits,
    }) => {
      if ([null, undefined].includes(value)) {
        return '-';
      }

      const localeOptions = {
        minimumFractionDigits: Number(fixedDigits ?? 2),
        maximumFractionDigits: Number(fixedDigits ?? 2),
      };

      const symbolsBefore = ['$', '#'];
      const symbolsAfter = ['%'];

      const localeValue = value.toLocaleString('en-US', localeOptions);
      let prefix = '';
      if (goalType === 'lessThan') {
        prefix = '< ';
      } else if (goalType === 'greaterThan') {
        prefix = '> ';
      }
      if (symbolsBefore.includes(units)) {
        return `${prefix}${units}${localeValue}`;
      }
      if (symbolsAfter.includes(units)) {
        return `${prefix}${localeValue}${units}`;
      }
      if (units) {
        return `${prefix}${localeValue} ${units}`;
      }
      return `${prefix}${localeValue}`;
    },
    []
  );
}

function ValueWithUnits({
  // Props
  value,
  units,
  goalType,
  fixedDigits,
  tooltip,
}) {
  const render = useValueWithUnits();
  const string = React.useMemo(
    () => render({ value, units, goalType, fixedDigits }),
    [fixedDigits, goalType, render, units, value]
  );
  if (tooltip) {
    return (
      <Tooltip title={<TextWithNewlines text={tooltip} fontSize="inherit" />}>{string}*</Tooltip>
    );
  }
  return string;
}

export default React.memo(ValueWithUnits);
